<template>
  <v-container v-if="data">
    <v-row class="pa-0 ma-0">
      <h3>User-Informationen</h3>
    </v-row>
    <!-- location -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Ort:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{data.product.locations[0].name}}
      </v-col>
    </v-row>
    <!-- frequency -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Häufigkeit:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{mapFrequency()}}
      </v-col>
    </v-row>
    <!-- the current hour -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Uhrzeit:
      </v-col>
      <v-col class="pa-0 ma-0">
        {{new Date(Date.now()).getHours()}} Uhr
      </v-col>
    </v-row>
    <!-- Created date -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        erstellt am:
      </v-col>
      <v-col v-if="data.user" class="pa-0 ma-0">
        {{ new Date(data.user.ct).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- mailStatus -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Status:
      </v-col>
      <v-col v-if="data.user" class="pa-0 ma-0">
        {{mapMemorialMailStatus()}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    mapFrequency () {
      this.$store.commit('MapFrequency', this.data.product.cycle)
      return this.$store.getters.frequency
    },
    mapMemorialMailStatus () {
      this.$store.commit('MapMemorialMailStatus', this.data.user.status)
      return this.$store.getters.memorialMailStatus
    }
  }
}
</script>
