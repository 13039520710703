<template>
  <v-container v-if="data">
    <v-row class="pa-0 ma-0">
      <h3>Seiteninformationen</h3>
    </v-row>
    <!-- page id -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Page_ID:
      </v-col>
      <v-col v-if="data.product.pageSkeleton" class="pa-0 ma-0">
        {{data.product.pageSkeleton.page_id}}
      </v-col>
    </v-row>
    <!-- visibility -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Sichtbarkeit:
      </v-col>
      <v-col v-if="data.product.pageSkeleton" class="pa-0 ma-0">
        {{mapVisibility()}}
      </v-col>
    </v-row>
    <!-- Created date -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        erstellt am:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{new Date(data.product.ct).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
    <!-- the number of candles and comments -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Kommentare:
      </v-col>
      <v-col v-if="data.product && data.product.comments" class="pa-0 ma-0">
        {{data.product.candles.length + data.product.comments.content.length}}
      </v-col>
    </v-row>
    <!-- visitors -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Besucher:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{data.product.visitors}}
      </v-col>
    </v-row>
    <!-- Sympathies -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Anteilnahmen:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{data.product.sympathy}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    mapVisibility () {
      this.$store.commit('MapVisibility', this.data.product.pageSkeleton.visibility)
      return this.$store.getters.visibility
    }
  }

}
</script>
