<template>
  <div style="padding: 30px">
    <p class="font-weight-bold">
      Vergangene Zahlungsevents
    </p>
    <!-- all the maded payments -->
    <span v-for="(item, i) in data.payments" :key="i">
      <v-row class="pa-0 ma-0">
        <v-col cols="4" class="pa-0 ma-0">
          {{ new Date(item.ct).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) }}
        </v-col>
        <v-col cols="4" class="pa-0 ma-0">
          {{ item.invoiceNumber }}
        </v-col>
        <v-col cols="4" class="pa-0 ma-0">
          <span style="text-decoration: underline; cursor: pointer" @click="loadMore()">
            Rechnung öffnen
          </span>
        </v-col>
      </v-row>
    </span>
    <!-- suscription date -->
    <span>
      <v-row>
        <v-col cols="4 " class="pa-0 ma-0">
          {{ new Date(data._ct).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) }}
        </v-col>
        <v-col cols="4" class="pa-0 ma-0">
          Abo abgeschlossen
        </v-col>
      </v-row>
    </span>
    <p class="mt-5" style="text-decoration: underline; cursor: pointer" @click="loadMore()">
      Ältere Events anzeigen
    </p>
  </div>
</template>
<script>
export default {
  props: ['data'],
  methods: {
    loadMore () {
    }
  }
}
</script>
