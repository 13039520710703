<template>
  <v-container v-if="data">
    <v-row class="pa-0 ma-0">
      <h3>Seiteninformationen</h3>
    </v-row>
    <!-- page id -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Page_ID:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{data.product.pid}}
      </v-col>
    </v-row>
    <!-- type of candle -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        Art:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{MapArt()}}
      </v-col>
    </v-row>
    <!-- Created date -->
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        erstellt am:
      </v-col>
      <v-col v-if="data.product" class="pa-0 ma-0">
        {{new Date(data.product.ct).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    MapArt () {
      this.$store.commit('MapArt', this.data.product.type)
      return this.$store.getters.art
    }
  }
}
</script>
