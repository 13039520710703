<template>
  <div style="padding: 30px">
    <p class="font-weight-bold">
      Zukünftige Zahlungsevents
    </p>
    <v-row>
      <v-col col="10" class="py-0 my-0">
        <span>
          {{ new Date(data.abo.nextBillingDate).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) }}: {{ data.payment.amount/100 }}€ via {{ mapMethod() }}
        </span>
      </v-col>
      <v-col cols="2" class="py-0 my-0">
        <v-icon class="red--text" style="cursor: pointer">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-btn :disabled="aboInactive" class="red white--text mt-4" style="text-transform: none" @click="canceleAbo()">
      Abo kündigen
    </v-btn>
    <v-dialog
      v-model="canceleAboDialog"
      width="550"
    >
      <v-card class="pa-4">
        <v-card-text style="font-size: 19px;">
          Um dieses Abo zu kündigen, muss entschieden werden, wie mit dem damit verbundenen Produkt umgegangen wird
        </v-card-text>
        <div class="px-8">
          <v-checkbox
            v-if="data.abo.type === 'candle'"
            v-model="inactive"
            :label="`Es sollen keine neuen Kerzen mehr angezündet werden`"
          ></v-checkbox>
          <v-container
            class="pa-0"
            fluid
            v-if="data.abo.type === 'memorialMail'"
          >
            <v-radio-group v-model="radioGroup">
              <v-radio
                :label="`Die Gedenek-Post soll in die Basis-Variante wechseln`"
                @click="inactive = true"
              ></v-radio>
              <v-radio
                :label="`Die Gedenek-Post soll gar nicht mehr geschickt werden`"
                @click="inactive = false"
              ></v-radio>
            </v-radio-group>
            <v-divider dark = true></v-divider>
          </v-container>
          <v-checkbox
            v-if="data.abo.type === 'memorialPage'"
            v-model="inactive"
            :label="`Die Gedenekseite soll inaktiv weden`"
          ></v-checkbox>
          <v-checkbox
            v-model="informCustomer"
            :label="`Der Kunde soll per Mail über die Änderung informiert werden`"
          ></v-checkbox>
        </div>
        <v-card-text>
          Im nächsten Schritt kann eingestellt werden, wann die Änderung aktiv wird.
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                color="grey"
                width="100%"
                @click="canceleAboDialog = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="grey"
                width="100%"
                @click="nowCanceleAboDialog = true; canceleAboDialog = false"
              >
                Weiter
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="nowCanceleAboDialog"
      width="550"
    >
      <v-card class="pa-4">
        <v-card-text style="font-size: 19px;">
          Sollen die gewünschten Änderungen jetzt in Kraft treten oder erst mit Ende des bereits gezahlten Zeitraums?
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                color="grey"
                width="100%"
                @click="endNow = true; nowCanceleAboDialog = false; sureCanceleAboDialog = true"
              >
                Jetzt
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="grey"
                width="100%"
                @click="endNow = false; nowCanceleAboDialog = false; sureCanceleAboDialog = true"
              >
                Zum Ende
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="sureCanceleAboDialog"
      width="550"
      @input="alert=false"
    >
      <v-card class="pa-4">
        <v-card-title style="font-size: 19px; width: 100%" class="py-5 justify-center">
          Möchtest du dieses Abo wirklich kündigen
        </v-card-title>
        <v-alert
        type="error"
        v-model="alert"
      >{{alertMsg}}</v-alert>
        <v-card-text style="font-size: 19px; width: 100%" class="py-15 justify-center">
          Es werden alle zukünftigen Zahlungsevents gelöscht
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                color="grey"
                width="100%"
                @click="sureCanceleAboDialog = false"
              >
                Abbrechen
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="error"
                width="100%"
                @click="sendAboCancelation()"
              >
                Abo kündigen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <unvergessen-success-dialog :dialog="success" :buttonText="'Everything went well'" @close="success=false"></unvergessen-success-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import SuccessDialog from '@/components/global/SuccessDialog.vue'
export default {
  props: ['data'],
  data () {
    return {
      aboInactive: false,
      nowCanceleAboDialog: false,
      sureCanceleAboDialog: false,
      canceleAboDialog: false,
      inactive: false,
      informCustomer: false,
      endNow: false,
      success: false,
      alertMsg: '',
      alert: false
    }
  },
  methods: {
    canceleAbo () {
      this.canceleAboDialog = true
    },
    mapMethod () {
      this.$store.commit('MapMethod', this.data.payment.method)
      return this.$store.getters.method
    },
    sendAboCancelation () {
      axios.post('/crm/payment/cancelAbo', {
        id: this.data.payment._id,
        inactivate: this.inactive,
        informCustomer: this.informCustomer,
        endNow: this.endNow
      })
        .then(res => {
          this.aboInactive = true
          this.success = true
          this.sureCanceleAboDialog = false
          this.$emit('newData', res.data)
        }).catch(err => {
          this.alertMsg = err.response.data.msg
          this.alert = true
        })
    }
  },
  created () {
    if (this.data.abo.status.type === 'inactive') {
      this.aboInactive = true
    } else {
      this.aboInactive = false
    }
  },
  components: {
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>
