<template>
  <div v-if="requestData && requestData.payment">
    <p style="cursor: pointer" @click="$router.push({ name: 'Accounting' })"><v-icon>mdi-chevron-left</v-icon> zurück</p>
    <!-- Headline -->
    <h1 class="py-4 my-0" >
      <!-- TODO: Fix -->
      {{mapTypeName()}}
    </h1>

    <v-card width="100%" class="unvergessen-shadow">
      <!-- Mail -->
      <div style="padding: 20px; text-align: center" class="grey3 title">
        {{ requestData.payment.mail }}
      </div>

      <!-- Content -->
      <div>
        <v-container class="py-0">
          <v-row>
            <!-- Basic Info -->
            <v-col cols="6" class="user-col"
            v-if="!(requestData.payment.type === 'OTP' &&
            (requestData.payment.product.type ==='memorialProducts' ||
            requestData.payment.product.type ==='marketing'))">
              <unvergessenUserInfoMail
              :data = 'requestData'
              v-if="((requestData.payment.type === 'ABO' && requestData.payment.product.type === 'memorialMail') ||
              (requestData.payment.type === 'OTP' && requestData.payment.product.type === 'memorialMail'))"
              ></unvergessenUserInfoMail>
              <unvergessenPageInfo
              :data = 'requestData'
              v-if="((requestData.payment.type === 'ABO' && requestData.payment.product.type === 'memorialPage') ||
              (requestData.payment.type === 'OTP' && requestData.payment.product.type === 'memorialPage'))"></unvergessenPageInfo>
              <unvergessenCandleInfo
              :data = 'requestData'
              v-if="(requestData.payment.product.type === 'candle')"></unvergessenCandleInfo>
              <unvergessenPartnerInfo
              v-if="((requestData.payment.type === 'OTP' && requestData.payment.product.type === 'marketing') ||
              (requestData.payment.type === 'OTP' && requestData.payment.product.type === 'memorialProducts'))"></unvergessenPartnerInfo>
            </v-col>
            <v-col style="padding: 30px" cols="6" class="user-col" v-else>
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <p class="font-weight-bold">
                    Partner-Informationen
                  </p>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  Do not put anything in here
                </v-col>
              </v-row>
            </v-col>

            <!-- Payment Info -->
            <v-col cols="6" class="user-col">
              <unvergessenPayInfo
              class="pa-3"
              :data = 'requestData'
              :externalPayId = 'requestData.payment.externalPaymentID'
              ></unvergessenPayInfo>
            </v-col>

            <!-- Past Payments -->
            <v-col cols="6" class="user-col" v-if="requestData.abo">
              <unvergessenPastPayments
              :data ='requestData.abo'></unvergessenPastPayments>
            </v-col>
            <v-col style="padding: 30px" cols="6" class="user-col"
            v-if="requestData.payment.type === 'OTP' &&
            !(requestData.payment.product.type ==='memorialProducts' ||
            requestData.payment.product.type ==='marketing')">
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <p class="font-weight-bold">
                    Vergangene Zahlungsevents
                  </p>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  Keine
                </v-col>
              </v-row>
            </v-col>
            <!-- Future Payments -->
            <v-col cols="6" class="user-col" v-if="requestData.abo">
              <unvergessenFuturePayments
              :data ='requestData' @newData="updateData"></unvergessenFuturePayments>
            </v-col>
            <v-col style="padding: 30px" cols="6" class="user-col"
            v-if="requestData.payment.type === 'OTP' &&
            !(requestData.payment.product.type ==='memorialProducts' ||
            requestData.payment.product.type ==='marketing')">
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <p class="font-weight-bold">
                    Zukünftige Zahlungsevents
                  </p>
                </v-col>
              </v-row>
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  Keine
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>

    <v-card width="100%" class="unvergessen-shadow my-4">
      <!-- title -->
      <div style="padding: 20px; text-align: center" class="grey3 title">
        Notizen
      </div>

      <!-- Content -->
      <div>
        <v-container class="py-0">
          <v-row>
            <v-col cols="6" class="user-col">
              <v-textarea outlined class="py-0" v-model="editedNote" @input="isEditable()"></v-textarea>
              <div style="text-align: right">
                <v-btn class="success" @click="editNote()" :disabled='editable'>
                  Speichern
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" class="user-col">
              <span>payments log</span>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
    <!-- Storno Dialog -->
    <!-- TODO: Fix -->
    <v-dialog max-width="600px" v-model="stornoDialog " @click:outside="stornoDialog = false">
      <unvergessen-storno-dialog :item="user" :dialog="stornoDialog" @closeDialog="stornoDialog = false" @updateObject="updateObject($event)"></unvergessen-storno-dialog>
    </v-dialog>
  </div>
</template>

<script>
import StornoDialog from '@/components/finance/accounting/Storno.vue'
import PayInfo from '@/components/finance/accounting/PayInfo.vue'
import UserInfoMail from '@/components/finance/accounting/UserInfoMail.vue'
import PageInfo from '@/components/finance/accounting/PageInfo.vue'
import CandleInfo from '@/components/finance/accounting/InfoCandle.vue'
import PartnerInfo from '@/components/finance/accounting/PartnerInfo.vue'
import PastPayments from '@/components/finance/accounting/PastPayments.vue'
import FuturePayments from '@/components/finance/accounting/FuturePayments.vue'
import axios from 'axios'

export default {
  data () {
    return {
      typeName: '',
      requestData: {},
      noteText: '',
      editedNote: '',
      editable: true,
      stornoDialog: false,
      user: {}
    }
  },
  computed: {
    uid () {
      return this.$route.params.uid || ''
    }
  },
  methods: {
    updateData (data) {
      this.requestData = data
    },
    mapTypeName () {
      var name = ''
      var type = ''
      this.$store.commit('MapTypeName', this.requestData.payment.product.type)
      if (this.requestData.payment.type === 'OTP') {
        type = 'Einmalzahlung'
      } else {
        type = 'Abo'
      }
      name = this.$store.getters.typeName + ' ' + type
      return name
    },
    editNote () {
      axios.patch('/crm/payment/notes', { id: this.requestData.payment._id, notes: this.editedNote })
        .then(() => {
          this.noteText = this.editedNote
          this.editable = true
        })
        .catch((e) => {
          console.log(e)
        })
    },
    isEditable () {
      if (this.noteText === this.editedNote) {
        this.editable = true
      } else {
        this.editable = false
      }
    },
    deleteEvent (item) {
      // TODO
      // console.log('deleteEvent: ' + item)
    },
    openInvoice (item) {
      // TODO
      // console.log('openInvoice: ' + item)
    },
    loadMore () {
      // TODO
    }
  },
  created () {
    axios.get('/crm/payment/single', { params: { id: this.uid } }).then((res) => {
      this.requestData = res.data
      this.noteText = res.data.payment.notes.content
      this.editedNote = this.noteText
      this.user = res.data.user || {}
    })
  },
  components: {
    unvergessenStornoDialog: StornoDialog,
    unvergessenPayInfo: PayInfo,
    unvergessenUserInfoMail: UserInfoMail,
    unvergessenPageInfo: PageInfo,
    unvergessenCandleInfo: CandleInfo,
    unvergessenPartnerInfo: PartnerInfo,
    unvergessenPastPayments: PastPayments,
    unvergessenFuturePayments: FuturePayments
  }
}
</script>

<style>
.user-col {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc
}
</style>
